import { Controller } from "@hotwired/stimulus"
import "ninja-keys"

export default class extends Controller {
  connect() {
    console.log("[Command Palette Controller] CONNECTED")
    super.connect()
    this.element.data = [
      {
        id: 'home',
        title: 'Homepage',
        hotkey: 'alt+1',
        section: 'Pages',
        handler: () => {
          Turbo.visit("/");
        },
      },
      {
        id: 'news',
        title: 'News',
        hotkey: 'alt+2',
        section: 'Pages',
        handler: () => {
          Turbo.visit("/news");
        },
      },
      {
        id: 'feed',
        title: 'My Feed',
        hotkey: 'alt+3',
        section: 'Pages',
        handler: () => {
          Turbo.visit("/feed");
        },
      },
      {
        id: 'undark',
        title: 'Undark',
        section: 'Users',
        handler: () => {
          Turbo.visit("/@Undark");
        },
      },
    ]
  }
}