import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import {Database} from "emoji-picker-element";
const database = new Database();
console.log(database)

export default class extends Controller {
  static targets = ["field"]
  connect() {
    console.log('[MentionsController.js] Connected')
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      menuItemTemplate:  function(item) {
        const initialsTemplate = `
           <div class="relative inline-flex items-center justify-center w-8 h-8 p-4 mr-2 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span class="font-medium text-gray-600 dark:text-gray-300">${item.original.initials}</span>
          </div>
        `;
        const avatarTemplate = `
            <img class="w-8 h-8 rounded-full inline-flex mr-2" src="${item.original.avatar_url}">
        `;
        const profileIconTemplate = item.original.avatar_url ? avatarTemplate : initialsTemplate;
        return `
        ${profileIconTemplate} 
        
        ${item.string}`
      },
      containerClass: "tribute-container left-[600px] z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-800 border",
      itemClass: "flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white",
      allowSpaces: false,
      searchOpts: {
        pre: '<span class="dark:text-primary-300">',
        post: '</span>'
      },
      lookup: x => x.gamertag,
      values: this.fetchUsers,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback(error))
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.fieldTarget.editor.getPosition()
    console.log(position, startPos, endPos)
    console.log(this.fieldTarget.editor)
    this.element.focus()
    this.fieldTarget.editor.setSelectedRange([startPos, position])
    // this.fieldTarget.editor.deleteInDirection("backward")
  }
}
